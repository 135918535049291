import * as actionTypes from '../actions/actionTypes';
import axios from 'axios';
import axiosClient from '../httpClient';
import { url } from '../components/Constants/Constants';
import { check_auth_error } from '../components/Utility/Utility';
import { fetchHotels, setSelectedFiltersForAll, setSelectedHotel } from '../commons/components/Filter/actions/actions';
import { commonFetchData } from '../commons/utility';

export function setToInitial(){
  return {
    type: actionTypes.SET_TO_INITIAL
  }
}

export function updateAuthToken(authToken) {
  return {
    type: actionTypes.AUTH_TOKEN,
    payload: authToken,
  };
}

export function updateSelectedTab(tab) {
  return {
    type: actionTypes.SELECTED_TAB,
    payload: tab,
  };
}

export const fetchUserDetailSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
    payload: user
  }
}

export function fetchUserDetail() {
  return function(dispatch, getState){
    const {
      filter: { selectedHotel,hotels },
      auth:{token}
    } = getState(); 
    if(!token)
      return
    const URL = selectedHotel?.hotel_code?`/accounts/user/?hotel=${selectedHotel?.hotel_code}`:`/accounts/user/`
    dispatch({type: actionTypes.FETCH_USER_DETAIL_REQUEST});
    axiosClient.get(URL)
    .then(async(res) => { dispatch(fetchUserDetailSuccess(res.data))
      let settings = res?.data?.profile?.settings || null
//       let hotels = await commonFetchData(`/services/hotels/?subscription=true&competitor=false`,{
//         onErrorData:[],
//         req:'get'
//       })
// console.log(hotels,"login action");
let hotels = res?.data?.profile?.hotels || []

    if(settings?.selected_hotel_code){
      const hotel = hotels.find(e=>e.hotel_code == settings.selected_hotel_code)
      if(hotel){
        // dispatch(setSelectedHotel(hotel))
          if(settings?.selected_filters)
            dispatch(setSelectedFiltersForAll(settings.selected_filters)) 
      }
    }
    }).catch((error) => {
      // check_auth_error(error);
      dispatch({type: actionTypes.FETCH_USER_DETAIL_ERROR});  
      console.log(error)
    });
  }
}

export function logout(){
  return function(dispatch, getState){
    const {
      filter: { selectedFilters,selectedHotel },
    } = getState(); 
    let body = { settings: {} };

    if (Object.keys(selectedFilters).length > 0) {
      body.settings.selected_filters = selectedFilters;
    }

    if (selectedHotel?.hotel_code) {
      body.settings.selected_hotel_code = selectedHotel.hotel_code;
    }

    if (Object.keys(body.settings).length === 0) {
      delete body.settings;
    }

    axiosClient.post('/accounts/logout/', body).
    then((res) => {console.log('Logout success'); 
      
      dispatch({
        type:actionTypes.UPDATE_FIREBASE_CRED,
        payload:{
          fcm_token:null,
          device_id:null
        }
      })
      dispatch(setToInitial())}).
    catch((error) => {console.log(`Error logging out: ${error}`); dispatch(setToInitial())});

  }
}

